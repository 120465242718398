import { __assign, __awaiter, __generator } from "tslib";
import * as sessionReplay from '@amplitude/session-replay-browser';
import { VERSION } from './version';
var SessionReplayPlugin = /** @class */function () {
  function SessionReplayPlugin(options) {
    this.name = '@amplitude/plugin-session-replay-browser';
    this.type = 'enrichment';
    this.options = __assign({}, options);
    // The user did not explicitly configure forceSessionTracking to false, default to true.
    if (this.options.forceSessionTracking !== false) {
      this.options.forceSessionTracking = true;
    }
  }
  SessionReplayPlugin.prototype.setup = function (config) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function () {
      var error_1;
      return __generator(this, function (_e) {
        switch (_e.label) {
          case 0:
            _e.trys.push([0, 2,, 3]);
            config.loggerProvider.log("Installing @amplitude/plugin-session-replay, version ".concat(VERSION, "."));
            this.config = config;
            if (this.options.forceSessionTracking) {
              if (typeof config.defaultTracking === 'boolean') {
                if (config.defaultTracking === false) {
                  config.defaultTracking = {
                    pageViews: false,
                    formInteractions: false,
                    fileDownloads: false,
                    sessions: true
                  };
                }
              } else {
                config.defaultTracking = __assign(__assign({}, config.defaultTracking), {
                  sessions: true
                });
              }
            }
            return [4 /*yield*/, sessionReplay.init(config.apiKey, {
              instanceName: this.config.instanceName,
              deviceId: this.config.deviceId,
              optOut: this.config.optOut,
              sessionId: this.config.sessionId,
              loggerProvider: this.config.loggerProvider,
              logLevel: this.config.logLevel,
              flushMaxRetries: this.config.flushMaxRetries,
              serverZone: this.config.serverZone,
              sampleRate: this.options.sampleRate,
              privacyConfig: {
                blockSelector: (_a = this.options.privacyConfig) === null || _a === void 0 ? void 0 : _a.blockSelector,
                maskSelector: (_b = this.options.privacyConfig) === null || _b === void 0 ? void 0 : _b.maskSelector,
                unmaskSelector: (_c = this.options.privacyConfig) === null || _c === void 0 ? void 0 : _c.unmaskSelector,
                defaultMaskLevel: (_d = this.options.privacyConfig) === null || _d === void 0 ? void 0 : _d.defaultMaskLevel
              },
              debugMode: this.options.debugMode,
              configEndpointUrl: this.options.configEndpointUrl,
              shouldInlineStylesheet: this.options.shouldInlineStylesheet,
              version: {
                type: 'plugin',
                version: VERSION
              },
              performanceConfig: this.options.performanceConfig,
              storeType: this.options.storeType
            }).promise];
          case 1:
            _e.sent();
            return [3 /*break*/, 3];
          case 2:
            error_1 = _e.sent();
            config.loggerProvider.error("Session Replay: Failed to initialize due to ".concat(error_1.message));
            return [3 /*break*/, 3];
          case 3:
            return [2 /*return*/];
        }
      });
    });
  };
  SessionReplayPlugin.prototype.execute = function (event) {
    return __awaiter(this, void 0, void 0, function () {
      var sessionRecordingProperties, error_2;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            _a.trys.push([0, 3,, 4]);
            if (!(this.config.sessionId && this.config.sessionId !== sessionReplay.getSessionId())) return [3 /*break*/, 2];
            return [4 /*yield*/, sessionReplay.setSessionId(this.config.sessionId).promise];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            // Treating config.sessionId as source of truth, if the event's session id doesn't match, the
            // event is not of the current session (offline/late events). In that case, don't tag the events
            if (this.config.sessionId && this.config.sessionId === event.session_id) {
              sessionRecordingProperties = sessionReplay.getSessionReplayProperties();
              event.event_properties = __assign(__assign({}, event.event_properties), sessionRecordingProperties);
            }
            return [2 /*return*/, Promise.resolve(event)];
          case 3:
            error_2 = _a.sent();
            this.config.loggerProvider.error("Session Replay: Failed to enrich event due to ".concat(error_2.message));
            return [2 /*return*/, Promise.resolve(event)];
          case 4:
            return [2 /*return*/];
        }
      });
    });
  };
  SessionReplayPlugin.prototype.teardown = function () {
    return __awaiter(this, void 0, void 0, function () {
      return __generator(this, function (_a) {
        try {
          sessionReplay.shutdown();
          // the following are initialized in setup() which will always be called first
          // here we reset them to null to prevent memory leaks
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.config = null;
        } catch (error) {
          this.config.loggerProvider.error("Session Replay: teardown failed due to ".concat(error.message));
        }
        return [2 /*return*/];
      });
    });
  };
  SessionReplayPlugin.prototype.getSessionReplayProperties = function () {
    return sessionReplay.getSessionReplayProperties();
  };
  return SessionReplayPlugin;
}();
export { SessionReplayPlugin };
export var sessionReplayPlugin = function (options) {
  return new SessionReplayPlugin(options);
};
